import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import RegistrationHeader from '../RegistrationHeader';

export default function Register() {
   
    const [selecteddiv, setselecteddiv] = useState('registrationForm');

    const [visible, setvisible] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();

        setEmail('');
        setContact('');
        setaddress('');
        setpincode('');
        setPassword('');
        setconfPass('');

        setEmailError(false);
        setContactError(false);
        setPasswordError(false);
        setconfPassError(false);

        setchildName('');
        setdob('');
        setyearGrp('');
        setchildDetails('');
        setchildAllergies('');
    };

    const [parentName, setparentName] = useState(''); // Of parent registration page
    const [address, setaddress] = useState('');
    const [pincode, setpincode] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [password, setPassword] = useState('');
    const [confPass, setconfPass] = useState('');

    const [emailError, setEmailError] = useState(false);
    const [contactError, setContactError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confPassError, setconfPassError] = useState(false);

    const [childName, setchildName] = useState(''); // Of child registration page
    const [dob, setdob] = useState('');
    const [yearGrp, setyearGrp] = useState('');
    const [childDetails, setchildDetails] = useState('');
    const [childAllergies, setchildAllergies] = useState('');

    const handleparentName = (e) => {
        let pname = e.target.value;
        setparentName(pname);
    };

    const handleaddress = (e) => {
        let addr = e.target.value;
        setaddress(addr);
    };

    const handlepincode = (e) => {
        let code = e.target.value;
        setpincode(code);
    };

    const handleEmail = (e) => {
        let mail = e.target.value;
        setEmail(mail);

        const emailValidation = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        const isValidEmail = emailValidation.test(mail);

        if (!isValidEmail) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    };

    const handleContact = (e) => {
        let mob = e.target.value;
        setContact(mob);

        const contactValidation = /^\d{10}$/;

        const isValidPhoneNumber = contactValidation.test(mob);

        if (!isValidPhoneNumber) {
            setContactError(true);
        } else if (isNaN(contact)) {
            setContactError(true);
        }
        else {
            setContactError(false);
        }
    };

    const handlePassword = (e) => {
        let pass = e.target.value;
        setPassword(pass);

        if (password.length < 6) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }
    };

    const handleConfpass = (e) => {
        let cpass = e.target.value;
        setconfPass(cpass);

        if (confPass !== password) {
            setconfPassError(true);
        } else {
            setconfPassError(false);
        }
    };

    const handlechildName = (e) => {
        let child = e.target.value;
        setchildName(child);
    };

    const handledob = (e) => {
        let date = e.target.value;
        setdob(date);
    };

    const handleyearGrp = (e) => {
        let year = e.target.value;
        setyearGrp(year);
    };

    const handlechildDetails = (e) => {
        let details = e.target.value;
        setchildDetails(details);
    };

    const handlechildAllergies = (e) => {
        let allergies = e.target.value;
        setchildAllergies(allergies);
    };

    return (
        <div>
            <header>
                <nav className="navbar navbar-expand-lg bg-body-tertiary">
                    <div className="container-fluid custom-padding">
                        <Link className="navbar-brand" to="/">
                            <img src="https://www.robothink.co.uk/wp-content/uploads//2020/01/image.svg" alt=""
                                className="logo_img" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">About</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">Contact</Link>
                                </li>
                                <li className="nav-item custom-btn">
                                    <button className='nav-link text-white'
                                        onClick={() => {
                                            setselecteddiv('registrationForm');
                                        }}>Parent Login</button>
                                </li>
                                <li className="nav-item custom-btn">
                                    <Link className="nav-link text-white" to="/">Find RoboThink</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            <main className="main-bg">
                <div className="container">
                    <div className="form_div text-center mt-5">
                        <form action='' className="login_form" id="registrationForm" onSubmit={handleSubmit} style={selecteddiv == "registrationForm" ? { display: 'block' } : { display: 'none' }}>
                            <h2 className="mb-4 text-clr">Register</h2>
                            <div className="mb-4 position-relative">
                                <label htmlFor="parentForename" className="form-label custom_lable">Parent/Guardian Name*</label>
                                <input type="text" className="form-control" id="parentForename" autoComplete='off' onChange={handleparentName} value={parentName} required />
                                <span id="forenameError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="parentSurname" className="form-label custom_lable">Your current location</label>
                                <input type="text" id="locationInput" autoComplete='off' className="form-control" onChange={handleaddress} value={address} />
                                <span id="locationError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="parentSurname" className="form-label custom_lable">ZIP code</label>
                                <input type="text" id="zip_code" autoComplete='off' className="form-control" onChange={handlepincode} value={pincode} />
                                <span id="zipError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="email" className="form-label custom_lable">Email Address*</label>
                                <input type="text" className="form-control" autoComplete='off' id="email" onChange={handleEmail} value={email} required />
                                <span id="emailError" className="error">{emailError ? "Invalid email format" : ""}</span>
                            </div>
                            <div className="mb-3 position-relative">
                                <label htmlFor="contactNumber" className="form-label custom_lable">Contact Number*</label>
                                <input type="text" className="form-control" autoComplete='off' id="contactNumber" onChange={handleContact} value={contact} required />
                                <span id="contactError" className="error">{contactError ? "Contact number should be of 10 digits" : contactError ? "Contact number should not contain any letters or special character" : ""}</span>
                            </div>
                            <div className="mb-3 position-relative">
                                <label htmlFor="inputPassword" className="form-label custom_lable">Password*</label>
                                <input type={visible ? "text" : "password"} className="form-control" autoComplete='off' id="inputPassword" onChange={handlePassword} value={password} required />
                                <span id="passwordError" className="error">{passwordError ? "Password must be atleast 6 character" : ""}</span>
                                <span className="pass_hide" onClick={() => {
                                    setvisible(!visible);
                                }}>
                                    {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </span>
                            </div>
                            <div className="mb-3 position-relative">
                                <label htmlFor="confirmPassword" className="form-label custom_lable">Confirm Password*</label>
                                <input type={visible ? "text" : "password"} className="form-control" autoComplete='off' id="confirmPassword" onChange={handleConfpass} value={confPass} required />
                                <span id="confirmPasswordError" className="error">{confPassError ? "Confirm password did not match" : ""}</span>
                                <span className="pass_hide" onClick={() => {
                                    setvisible(!visible);
                                }}>
                                    {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </span>
                            </div>
                            <div className="mb-3">
                                <button className="login-btn" type="submit"
                                    onClick={() => {
                                        setselecteddiv('another_form');
                                    }}>Next</button>
                            </div>
                            <div className="register_div text-center">
                                <Link to="/login" className="btn_forgot">Login</Link>
                            </div>
                        </form>

                        <form action='' className="another_form login_form" id="another_form" onSubmit={handleSubmit} style={selecteddiv == "another_form" ? { display: 'block' } : { display: 'none' }}>
                            <h2 className="mb-4 text-clr">Add Child</h2>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childName" className="form-label custom_lable">Name of child*</label>
                                <input type="text" className="form-control" id="childName" onChange={handlechildName} value={childName} required />
                                <span id="childNameError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childDOB" className="form-label custom_lable">Date of Birth*</label>
                                <input type="date" className="form-control" id="childDOB" onChange={handledob} value={dob} required />
                                <span id="childDOBError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="yearGroup" className="form-label custom_lable">Year Group*</label>
                                <select name="year_group" id="yearGroup" onChange={handleyearGrp} value={yearGrp} required>
                                    <option value="">Select Year</option>
                                    <option value="1">1 Year</option>
                                    <option value="2">2 Year</option>
                                    <option value="3">3 Year</option>
                                    <option value="4">4 Year</option>
                                    <option value="5">5 Year</option>
                                    <option value="6">6 Year</option>
                                </select>
                                <span id="yearGroupError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childDetails" className="form-label custom_lable">Additional details about your child</label>
                                <input type="text" className="form-control" id="childDetails" onChange={handlechildDetails} value={childDetails} required />
                                <span id="childDetailsError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childConditions" className="form-label custom_lable">Medical conditions or allergies</label>
                                <input type="text" className="form-control" id="childConditions" onChange={handlechildAllergies} value={childAllergies} required />
                                <span id="childConditionsError" className="error"></span>
                            </div>
                            <div className="mb-3">
                                <button className="login-btn add_my_child" type="submit">Add My Child</button>
                            </div>
                            <div className="register_div text-center">
                                <button className="btn_forgot add_another_child" type="button"
                                    onClick={() => {
                                        setselecteddiv('addAnothreChild');
                                    }}>Add Another Child</button>
                            </div>
                        </form>
                        <form action='' className="add_other_child login_form" id="addAnothreChild" onSubmit={handleSubmit} style={selecteddiv == "addAnothreChild" ? { display: 'block' } : { display: 'none' }}>
                            <h2 className="mb-4 text-clr">Add Another Child</h2>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childName" className="form-label custom_lable">Name of child*</label>
                                <input type="text" className="form-control" id="secChildName" onChange={handlechildName} value={childName} required />
                                <span id="childNameError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childDOB" className="form-label custom_lable">Date of Birth*</label>
                                <input type="date" className="form-control" id="SecChildDOB" onChange={handledob} value={dob} required />
                                <span id="childDOBError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="yearGroup" className="form-label custom_lable">Year Group*</label>
                                <select name="year_group" id="SecChildYearGroup" onChange={handleyearGrp} value={yearGrp} required>
                                    <option value="">Select Year</option>
                                    <option value="1">1 Year</option>
                                    <option value="2">2 Year</option>
                                    <option value="3">3 Year</option>
                                    <option value="4">4 Year</option>
                                    <option value="5">5 Year</option>
                                    <option value="6">6 Year</option>
                                </select>
                                <span id="yearGroupError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childDetails" className="form-label custom_lable">Additional details about your child</label>
                                <input type="text" className="form-control" id="secChildDetails" onChange={handlechildDetails} value={childDetails} required />
                                <span id="childDetailsError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childConditions" className="form-label custom_lable">Medical conditions or allergies</label>
                                <input type="text" className="form-control" id="secChildConditions" onChange={handlechildAllergies} value={childAllergies} required />
                                <span id="childConditionsError" className="error"></span>
                            </div>
                            <div className="mb-3">
                                <button className="login-btn add_my_child" type="submit">Add My Child</button>
                            </div>
                            <div className="register_div text-center">
                                <button className="btn_forgot add_another_child" type="button"
                                    onClick={() => {
                                        setselecteddiv('addAnothreChild');
                                    }}>Add Another Child</button>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
}
