import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function Login() {

    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        navigate("/home")
        setEmail('');
        setPassword('');
    };

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [visible, setvisible] = useState(true);

    const [emailError, setemailError] = useState(false);
    const [passError, setpassError] = useState(false);

    const handleEmail = (e) => {
        let mail = e.target.value;
        setEmail(mail);

        if (regex.test(email) === false) {
            setemailError(true);
        } else {
            setemailError(false);
        }
    };

    const handlePassword = (e) => {
        let pass = e.target.value;
        setPassword(pass);

        if (password.length < 6 || password == "") {
            setpassError(true);
        } else {
            setpassError(false);
        }
    };

    return (
        <div>
            <main className="main-bg">
                <div className="container">
                    <div className="form_div text-center mt-5">
                        <form action="/" className="login_form" onSubmit={handleSubmit}>
                            <h2 className="mb-4 text-clr">Login</h2>
                            <div className="mb-4 position-relative">
                                <label htmlFor="inputEmail4" className="form-label custom_lable">Email</label>
                                <input type="text" placeholder="your@email.com" autoComplete='off' className="form-control" id="inputEmail4" onChange={handleEmail} value={email} required />
                                <span id="emailError" className="error">{emailError ? "Invalid email format" : ""}</span>
                            </div>
                            <div className="mb-3 position-relative">
                                <label htmlFor="inputEmail4" className="form-label custom_lable">Password</label>
                                <input type={visible ? "text" : "password"} className="form-control" id="inputPassword" onChange={handlePassword} value={password} required />
                                <span id="passwordError" className="error">{passError ? "Password must be atleast 6 characters" : ""}</span>
                                <span className="pass_hide" onClick={() => {
                                    setvisible(!visible);
                                }}>
                                    {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </span>
                            </div>
                            <div className="mb-3">
                                <button  className="login-btn">Login</button>
                            </div>
                            <div className="forgotZ_btn_div">
                                <button className="btn_forgot">Forgot Password?</button>
                            </div>
                            <div className="register_div text-center">
                                <Link to="/register" className="btn_forgot">Register</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
}
