import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
export default function Navbar() {
    const location = useLocation();
   if(location.pathname=="/register"){
    return (
        <></>
    );

   }else{
    return (
        <div>
            <header>
                <nav class="navbar navbar-expand-lg bg-body-tertiary">
                    <div class="container-fluid custom-padding">
                        <Link class="navbar-brand" to="/">
                            <img src="https://www.robothink.co.uk/wp-content/uploads//2020/01/image.svg" alt=""
                                class="logo_img" />
                        </Link>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <Link class="nav-link" to="/manage-account">Manage Account</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" to="/my-students">My Students</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" to="/my-payments">My Payments</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" to="/my-classes">My Classes</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    );
}
}
