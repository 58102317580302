import './App.css';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/common-section/Navbar';
import HomePage from './components/HomePage';
import ManageAccount from './components/ManageAccount';
import MyStudents from './components/MyStudents';
import MyPayments from './components/MyPayments';
import MyClasses from './components/MyClasses';
import Footer from './components/common-section/Footer';
import Login from './components/signup-section/Login';
import Register from './components/signup-section/Register';
// import RegistrationHeader from './components/RegistrationHeader';
import Checkout from './components/Checkout';
import ClubSelection from './components/ClubSelection';
import Products from './components/Products';

function App() {

   
  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/' index element={<Login />}></Route>
        <Route path='/manage-account' element={<ManageAccount />}></Route>
        <Route path='/my-students' element={<MyStudents />}></Route>
        <Route path='/my-payments' element={<MyPayments />}></Route>
        <Route path='/my-classes' element={<MyClasses />}></Route>
        <Route path='/checkout' element={<Checkout />}></Route>
        <Route path='/club-selection' element={<ClubSelection />}></Route>
        <Route path='/products' element={<Products />}></Route>
        <Route path='/home' element={<HomePage />}></Route>
        <Route path='/register' headerMode={false} element={<Register />}></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
